.leftSection {
    width: 100%;
    border-left: 1px solid #707070;
    display: flex;
    flex-direction: column;
    position: relative;
}


.responsiveIframe {
    width: 100%;
    height: 550px;
    overflow: hidden;
    border: none;
}


.CloseBtn {
    width: 80px;
    height: 40px;
    background-color: #6665fd;
    color: white;
    font-size: 20px;
    border: none;
    outline: none;
    cursor: pointer;
    position: absolute;
    right: 0;
  }
