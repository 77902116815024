.loginWrapper {
  /* background: url("../../assets/HomeImages/BG-Latest.webp");
  background-size: cover;
  background-repeat: no-repeat; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 0 185px 0 111px;
  height: 775px; */
}

.chickenImageContainer {
  height: 642px;
  width: 642px;
}

.chickenImage {
  width: 100%;
  height: 100%;
}

.formOverlay {
  width: 100%;
  /* margin: 0 15% 0 0; */
  /* background: transparent linear-gradient(136deg, #333755 0%, #06081b 100%) 0%
    0% no-repeat padding-box;
  border: 1px solid #ffffff;
  border-radius: 22px;
  box-shadow: var(--formShadow); */
}

.formWrapper {
  /* padding: 40px; */
  color: var(--lightColor);
}

.formWrapper form {
  display: flex;
  flex-direction: column;
}

.loginHeader {
  display: flex;
  gap: 5px;
  justify-content: space-between;
  font-weight: 500;
  font-size: 1.2em;
  border-bottom: 1px solid #2c386e;
  padding-bottom: 10px;
}

.loginFormbody {
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.requiredLabel {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  padding-bottom: 5px;
}

.requiredLabel span {
  color: #f9a410;
  font-size: 20px;
}

.formInput {
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #3f519f;
  background: #2c386e;
  padding-left: 24px;
  position: relative;
}

.leftIconStyles {
  position: absolute;
  height: 100%;
  left: 10px;
  display: grid;
  place-items: center;
}

.formInput input {
  width: 100%;
  font-size: 16px;
  border: none;
  padding: 15px 0 15px 20px;
  color: var(--lightColor);
  background-color: transparent;
}

.formInput input:focus {
  outline: none;
}

.formInput input::placeholder {
  color: var(--lightColor);
  opacity: 0.3;
}

.inputPasswordWrapper {
  display: flex;
  position: relative;
}

.inputPasswordWrapper input {
  width: 83%;
  margin: 0 auto 0 0;
}

.inputPasswordWrapper img {
  cursor: pointer;
  margin-right: 15px;
  width: 20px;
}

.error {
  color: var(--redColor);
  font-size: 0.9rem;
  text-align: center;
}

.forgotPassword {
  display: flex;
  justify-content: flex-end;
  color: #00C1FE;
}

.forgotPassword div {
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}

.apiResponseLoader {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}

.apiResponseLoader div:nth-child(2) {
  padding: 0 15px;
  font-weight: bold;
  text-align: center;
}

.submitButton {
  margin-top: 20px;
  font-size: 1rem;
  padding: 15px 0px;
  cursor: pointer;
  width: 100%;
  color: var(--lightColor);
  /* background: transparent linear-gradient(180deg, #333755 0%, #040619 100%) 0%
    0% no-repeat padding-box;
  border: 1px solid #ffffff;
  border-radius: 18.5px; */
  border-radius: 8px;
  border: 1px solid #06b9f3;
  background: linear-gradient(180deg, #00c1fe 0%, #163d75 100%);
}

.submitButton:hover {
  background: var(--activeBtnHover);
}

.loading,
.loading:hover {
  border-color: var(--disableBkg);
  color: var(--disableColor);
  background: var(--disableBkg);
  cursor: not-allowed;
}

.registerSection {
  text-align: center;
}

.loginLink {
  margin-left: 0.4rem;
  cursor: pointer;
  color: #00c1fe;
  font-weight: 500;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.passwordContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
}

.passwordExample {
  color: #ffdb00;
  font-size: 10.3px;
  margin-bottom: 7.5px;
}