.modalTopOverlay {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: var(--modalOverlayBkg);
}

.modalOverlay {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalWrapper {
  display: flex;
  flex-direction: column;
  border: 0.3px solid var(--pageBorder);
  border-radius: var(--modalRadius);
  padding: 30px;
  width: 500px;
  background: linear-gradient(180deg, #1e2134 0%, #2c386e 100%);
  box-shadow: -2px 0px 0px 0px #00c1fe inset, 2px 0px 0px 0px #00c1fe inset;
}

.modalWrapper:focus {
  outline: none;
}

.playGameheader {
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  color: var(--lightColor);
  border-bottom: 1px dotted var(--pageBorder);
  font-size: 20px;
}

.balanceSectionWrapper {
  display: flex;
  align-items: center;
  gap: 3px;
}

.dbIconWrapper {
  display: flex;
}

.dbIconWrapper img {
  width: 40px;
  height: 40px;
}

.playGameBody {
  padding: 20px 0 0 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.playGameBodyDataHeading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  color: var(--lightColor);
  font-size: 18px;
  /* width: 30%; */
}

.playGameBodyDataValue {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  color: var(--lightColor);
  width: 35%;
}

.gameBalanceWrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.gameBalanceWrapper img {
  cursor: pointer;
  height: 16px;
  padding-left: 10px;
}

.balance {
  color: var(--goldColor);
  font-size: 18px;
}

.balanceError {
  color: var(--redColor);
  font-size: 14px;
  font-weight: bold;
}

.loaderWrapper {
  width: 80px;
}

.pointsContent {
  display: flex;
  gap: 10px;
  align-items: flex-end;
  align-items: flex-start;
  flex-direction: column;
}

.pointsContent input {
  width: 100%;
  border: none;
  font-size: 20px;
  color: var(--goldColor);
  padding: 0;
  background-color: transparent;
  border-bottom: 1px solid var(--lightColor);
}

.pointsContent input:focus {
  outline: none;
}

.minmaxWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 14px;
}

.transferAllBtnWrapper {
  display: flex;
  align-items: end;
  justify-content: center;
  width: 30%;
}

.transferAllBtn {
  cursor: pointer;
  padding: 5px 12px;
  text-align: center;
  color: var(--lightColor);
  font-weight: 600;
  border: 1px solid #06b9f3;
  background: linear-gradient(180deg, #06bbf7 0%, #17346c 100%);
  border-radius: 3px;
}

.transferAllBtn:hover {
  background: linear-gradient(360deg, #15c7fe 100%, #e9fef7 0%);
}

.loadingErrorWrapper {
  text-align: center;
}

.loadingErrorWrapper strong {
  color: var(--redColor);
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

.loadingErrorWrapper span {
  color: var(--lightColor);
}

.pointsInfo {
  color: var(--lightColor);
  text-align: center;
  font-size: 20px;
  margin: 10px 0;
}

.playGameFooter,
.playGameFooterDisable {
  display: flex;
  flex-direction: column;
}

.playGameFooter button,
.playGameFooterDisable button {
  cursor: pointer;
  padding: 10px 0;
  font-size: 20px;
  text-align: center;
  border-radius: 25px;
  color: var(--disableColor);
  border: 1px solid var(--disableBkg);
}

.playGameFooterDisable button {
  cursor: not-allowed !important;
}

.playGameFooter button {
  color: var(--lightColor);
  background: var(--activeBtn);
  border-radius: 28px;
  background: linear-gradient(180deg, #06bbf7 0%, #17346c 100%);
  border: 1px solid #06b9f3;
}

.playGameFooter button:hover {
  background: var(--activeBtnHover);
}