/* .modalOverlay {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
} */

/* .modalWrapper {
    width: 400Px;
  max-height: 50%;
  min-height: 400px;
  overflow: auto;
  color: var(--lightColor);
 background: #19253F 0% 0% no-repeat padding-box;
border: 1px solid #FFFFFF;
border-radius: 13px;
padding: 30px 20px;
} */
.modalMainWrapper {
  position: relative;
  border: none;
  background-color: transparent;
  outline: none;
  max-width: 420px;
}
.modalWrapper:focus {
  outline: none;
}
.modalMainWrapper > .modalWrapper > span {
  position: absolute;
  top: -33px;
  right: 10px;
  cursor: pointer;
  background-color: gray;
  display: flex;
  justify-content: center;
  align-items: center;
}
.titleWrapper {
  border-bottom: 1px solid #3f519f;
}
.titleWrapper > p {
  margin: 0;
  padding: 0 0 10px 0;
}
.smallContent {
  padding: 15px 0;
}
.smallContent > span {
  letter-spacing: 0px;
  color: #00c1fe;
  font-size: 14px;
}

.inputWrapperDiv {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 10px;
}

.requiredLabel {
  display: flex;
  gap: 5px;
  font-weight: 600;
  font-size: 14px;
}

.requiredLabel span {
  color: #c79353;
  font-size: 20px;
}
.formInput {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: transparent;
  text-align: left;
  /* border-bottom: 1px solid #707070; */
  border-radius: 8px;
  border: 1px solid var(--Colors-Stroke-colorStrokeInput, #3f519f);
  background: var(--Colors-Background-bgInput, #2c386e);
  padding: 10px 4px;
  padding-left: 26px;
  position: relative;
}

.formInput input {
  width: 100%;
  font-size: 16px;
  border: none;
  padding: 5px 0 5px 5px;
  color: #ffffff;
  background-color: transparent;
}

.leftIconStyles {
  position: absolute;
  height: 100%;
  left: 4px;
  top: 0;
  display: grid;
  place-items: center;
}

.formInput input:focus {
  outline: none;
}

.formInput input::placeholder {
  color: var(--inputPlaceholder);
  opacity: 50%;
}
.inputPasswordWrapper {
  display: flex;
  position: relative;
}

.inputPasswordWrapper input {
  width: 88%;
  margin: 0 auto 0 0;
}

.passwordEye {
  position: absolute;
  right: 8px;

  bottom: 14px;
}

.btn {
  margin-top: 30px;
  width: 100%;
  cursor: pointer;
  color: #dcf6fe;
  font-size: 16px;
  padding: 12px 0;
  border-radius: 8px;
  border: 1px solid #00c1fe;
  background: linear-gradient(180deg, #06bbf7 0%, #17346c 100%);
}

/* .btn:hover {
  background: var(--activeBtnHover);
} */

.btn:disabled,
.btn[disabled] {
  cursor: not-allowed;
  font-size: 18px;
  font-weight: bold;
  padding: 12px 0;
  border-radius: 8px;
  border: 1px solid transparent;
  box-shadow: none;
  background: #cccccc !important;
  color: #666666 !important;
  /* height: 45px */
}
.error {
  color: var(--redColor);
  font-size: 0.8rem;
}
