.profileLayoutOverlay {
  display: flex;
  align-items: center;
  padding: 3.5vh 10vh;
  background: url("../../assets/HomeImages/BG-Latest.webp");
  background-size: cover;
  background-repeat: no-repeat;
  /* height: 590px; */
  flex: 1;
}

.profileLayoutWrapper {
  display: flex;
  flex-direction: column;
  /* background: #1f2133 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 24px #00000029;
  border: 1px solid #a1a1a1; */
  /* backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px); */
  height: 598px;
  background: rgba(44, 56, 110, 0.5);
  backdrop-filter: blur(17.5px);
  border-radius: 16px;
  border: 2px solid #3f519f;
  width: 1000px;
  margin: 0 auto;
}

.profileLayoutHeader {
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--lightColor);
  padding: 15px;
  border-bottom: 1px solid var(--pageBorder);
  width: 95%;
  height: 7%;
  margin: 0 auto;
}

.joinedDate {
  color: var(--primaryColor);
  font-size: 15px;
}

.profileLayoutBody {
  display: flex;
  height: 100%;
  overflow: auto;
}

.profileTitle {
  color: white;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}

.horizontalLine {
  width: 90%;
  height: 1px;
  background: #3f519f;
  margin: 10px auto;
}

.profileLayoutAside {
  width: 20%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-top: 26px;
  /* border-right: 1px solid var(--pageBorder); */
  overflow: auto;
  background: #2c386e;
  backdrop-filter: blur(17.5px);
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  width: 200px;
}

.pageOption {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  color: var(--lightColor);
  /* padding-left: 18px; */
  border-left: 3px solid transparent;
  border-radius: 0 15px 15px 0;
}

.pageOptionIcons {
  width: 27px;
  height: 27px;
}
.pageOption > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.pageOptionContainer {
  display: flex;
  align-items: center;
  gap: 11px;
  cursor: pointer;
  color: var(--lightColor);
}

/* .pageOptionContainer:hover {
  color: var(--goldColor);
  border-color: var(--goldColor);
} */

.pageOptionName {
  font-size: 15px;
}

.activePage {
  color: var(--primaryColor);
  border-color: var(--primaryColor);
  border-radius: 0 15px 15px 0;
}

.pageOptionSide {
  height: 44px;
  /* width: 4px; */
  /* border-radius: 0 15px 15px 0; */
}

.activePageOption {
  /* background-color: var(--primaryColor); */
}

.profileLayoutMainOverlay {
  width: 80%;
  height: 100%;
  overflow: auto;
}

.profileLayoutMainOverlay::-webkit-scrollbar-thumb {
  background: transparent
    linear-gradient(90deg, rgb(55, 62, 73) 0%, rgb(65, 53, 53));
}

.profileLayoutMainWrapper {
  padding: 24px 40px;
  color: var(--lightColor);
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.pageTitle {
  font-size: 17px;
  font-weight: 600;
}
@media only screen and (max-width: 1440px) {
  .profileLayoutOverlay {
    padding: 2%;
    height: 100%;
  }

  .profileLayoutHeader {
    width: 96%;
  }
}
