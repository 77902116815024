.sponsorsWrapper {
  /* display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  padding: 20px 8%;
  height: 40px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 118px 0;
  color: var(--lightColor);
  background: var(--Colors-Background-bgInputHolder, #3F519F);
  height: 37px;
}

.gameList {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7.4px;
  cursor: pointer;
}

.otherDetails {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7.4px;
}

.otherDetailsList {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7.4;
}

.otherDetailsImage {
  width: 15px;
  height: 15px;
}