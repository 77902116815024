.wrapper {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  gap: 22px;
  padding: 24px;
  width: 70%;
  box-sizing: border-box;
  border-radius: var(--Sizes-Radius-radiius-medium, 16px);
  border: 2px solid var(--Silver, #eee);
  background: rgba(44, 56, 110, 0.6);
  backdrop-filter: blur(7.5px);
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 60px;
}

.container {
  /* width: 50%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.detailsTitle {
  color: #fff;
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.detailsDescription {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media only screen and (max-width: 1280px) {
  .wrapper {
    gap: 22px;
    margin-top: 74px;
    padding: 22px 74px 22px;
    margin-bottom: 74px;
  }

  .detailsTitle {
    font-size: 29.5px;
  }

  .detailsDescription {
    font-size: 16px;
  }
}
