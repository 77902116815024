.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 45px 0px;
  width: 100%;
}

.title {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0px;
  color: white;
  opacity: 1;
  min-width: fit-content;
  text-transform: uppercase;
  align-items: flex-start;
  display: flex;
  width: 100%;
}

.sectionContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-content: center;
  gap: 37px;
  margin-top: 16px;
  width: 100%;
}

.promotionList {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
}

.promotionItem {
  cursor: pointer;
  flex: 1 1 calc(33% - 20px);
  /* Adjust calculation based on margin */
  max-width: 33%;
  margin: 10px;
  /* Provides space around items, adjust as needed */
}

.promotionItem:hover {
  opacity: .7;
}

.promotionImage {
  width: 100%;
  height: 187px;
}

.promotionDetailsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 18px 0;
  border-radius: 0 0 15px 15px;
  background-color: #1f2133;
  height: 90px;
  margin-top: -7.5px;
}

.promotionTitle {
  color: white;
  font-size: 12px;
}

.promotionDate {
  color: white;
  font-size: 9px;
}

.promotionDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.promotionButton {
  background: transparent linear-gradient(180deg, #e9fef7 0%, #15c7fe 100%) 0% 0% no-repeat padding-box;
  border-radius: 24px;
  width: 96px;
  height: 22px;
  border: 0;
}

/* @media only screen and (max-width: 1366px) {
  .wrapper {
    margin-top: 74px;
  }

  .sectionTitle {
    gap: 22px;
  }

  .imgDivider {
    width: 332px;
    margin-bottom: -15px;
  }

  .sectionContent {
    gap: 22px;
    margin-top: 37px;
  }

  .promotionItem {
    width: 406px;
  }

  .promotionButton {
    width: 87px;
    height: 18px;
    font-size: 10px;
  }
} */