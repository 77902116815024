.profileWrapper {
  width: 40%;
  display: flex;
  flex-direction: column;
}

.profileWrapper div {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid var(--pageBorder);
  font-size: 15px;
}

.profileWrapper div:nth-last-child(1) {
  border-bottom: none;
}

.profileWrapper div span:nth-child(2) {
  width: 160px;
  color: var(--primaryColor);
  text-align: right;
  font-weight: 500;
}
