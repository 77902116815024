.qrImageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .logoContainer{
      width: 100px;
      height: 60px;
  }
  .logoContainer img{
      width: 100%;
      height: 100%;
      object-fit: contain;
  }
  .QRWrapper{
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 3px;
  }
  .qrCodeImg {
    height: 175px;
    width: 175px;
  }
  .redText {
    color: rgb(249, 59, 59);
    /* font-size: 17px; */
    text-align: center;
    overflow: hidden;
    font-weight: 600;
    /* font-size: 1.075rem; */
    /* margin-bottom: 10px; */
  }
 
 
  .download_button {
    margin-top: 5px;
    color: white;
    text-decoration: none;
    background-color: #1f2738;
    padding: 5px 14px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 14px;
  }
 
  /* Handler Responsiveness */
  @media only screen and (max-width: 430px) {
    .qrCodeImg {
      height: 150px;
      width: 150px;
    }
  }
 
  /* Handler Responsiveness */
  @media only screen and (max-width: 360px) {
    .qrCodeImg {
      height: 125px;
      width: 125px;
    }
  }
