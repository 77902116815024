.wrapper {
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center;
  margin-top: 30px; */
}

.sectionTitle {
  display: flex;
  align-items: center;
  gap: 18px;
  width: 100%;
  justify-content: center;
}

.title {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0px;
  color: white;
  opacity: 1;
  text-transform: uppercase;
  min-width: fit-content;
}

.imgDivider {
  width: 100%;
  margin-bottom: -14px;
}

.sectionContent {
  display: inline-block;  
  justify-content: center;
  gap: 37px;
  padding: 10px;
  margin-top: 16px;
}

.gameList {
  display: flex;
  gap: 16px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 16px;
}

.gameItem {
  cursor: pointer;
  height: auto;
}

.imageContainer {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
}

.gameImage {
  width: 404px;
  height: auto;
  cursor: pointer;
  border-radius: 16px;
  border: 2px solid #FFF;
  background: radial-gradient(50% 124% at 50% 50%, #2C386E 0%, #1E2134 100%);
  transition: opacity 0.3s ease;
}

.overlayButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #06B9F3;
  background: linear-gradient(180deg, #00C1FE 0%, #163D75 100%);
}

.overlayButton:hover {
  background: var(--activeBtnHover);
}