.sectionContent {
  display: flex;
  justify-content: center;
  gap: 25px;
  width: 100%;
  box-sizing: border-box;
  /* border: 3px solid #FFFF6D; */
  border-radius: 16px;
}

.loginForm {
  position: absolute;
  top: 0;
  right: 0;
  visibility: hidden;
}

.transactionButtons {
  position: absolute;
  top: 0;
  right: 0;
  display: none;
}

.imageContainer {
  position: relative;
  display: inline-block;
}

.overlayButton {
  position: absolute;
  top: 40%;
  left: 50%;
  cursor: pointer;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease;
  margin-top: 20px;
  font-size: 1rem;
  padding: 15px 30px;
  cursor: pointer;
  color: var(--lightColor);
  border-radius: 8px;
  border: 1px solid #06b9f3;
  background: linear-gradient(180deg, #00c1fe 0%, #163d75 100%);
}

.overlayButton:hover {
  background: var(--activeBtnHover);
}

.dagaTitleImage {
  border-radius: 16px;
  width: 100vw;
}

.imageContainer:hover .dagaTitleImage {
  opacity: 0.6;
  /* background: #000; */
}

.imageContainer:hover .overlayButton {
  opacity: 1;
}

.noImage {
  display: flex;
  justify-content: center;
  align-items: center;
}

.noImage>h4 {
  color: var(--lightColor);
  margin: 5px 0;
}