.convertionWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
  margin: 11px 0;
  color: var(--lightColor);
  /* background: #393d64 0% 0% no-repeat padding-box; */
  /* border: 1px solid #ffffff; */
  /* border-radius: 9px; */
  overflow: auto;
  font-size: 15px;
  border-radius: var(--Sizes-Radius-radius-small, 8px);
  background: var(--Colors-Background-bgInputHolder, #3f519f);
}

.depositConvertionWrapper {
  border-color: var(--lightColor);
}

.convertionContent {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #fff;
}

.convertionContent span {
  font-size: 24px;
  font-weight: 700;
}

.convertionContent span span {
  font-size: 24px;
  font-weight: 700;
}

/* style={{ color: '#fff', fontSize: "24px", fontWeight: 700 }} */