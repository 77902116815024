.popoverStyles {
  width: 500px;
  /* border-radius: var(--Sizes-Radius-radiius-medium, 16px);
  border: 2px solid var(--Colors-Stroke-colorStrokeInput, #3f519f);
  background: rgba(30, 33, 52, 0.65); */
  /* backdrop-filter: blur(17.5px); */
  padding: 20px;
  color: white;
  border-radius: 16px;
  background: linear-gradient(180deg, #1e2134 0%, #2c386e 100%);
  box-shadow: -2px 0px 0px 0px #00c1fe inset, 2px 0px 0px 0px #00c1fe inset;
}

.loginHeader {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  padding-bottom: 10px;
}

.walletSection {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  gap: 10px;
}

.dagaSection {
  position: relative;
  display: flex;
  justify-content: center;
}

.lineOne {
  position: absolute;
  top: 5px;
  width: 180px;
  border: 1px solid #FEE795;
  left: 0;
}

.dagaSectionImg {
  width: 89px;
  height: auto;
}

.lineTwo {
  position: absolute;
  width: 180px;
  border: 1px solid #FEE795;
  top: 5px;
  right: 0;
}

.dagaBalanceWrapper {
  display: flex;
  align-items: center;
  border-radius: 16px;
  background: #1E2134;
  padding: 16px;
}

.dagaBalance {
  color: #00C1FE;
  font-weight: 700;
  margin-right: 8px;
}

.dagaRefreshIcon {
  cursor: pointer;
}

.otherGameSection {
  position: relative;
  display: flex;
  justify-content: center;
}

.otherGameBalances {
  color: #00C1FE;
  font-weight: 700;
}

.gameSectionStyles {
  /* background: #1e2134 0% 0% no-repeat padding-box; */
  border-radius: 14px 14px 0 0;
  height: 400px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid #3F519F;
  margin-bottom: 12px;
  overflow: scroll;
}

.gameSection {
  color: #fff;
  font-weight: 700 !important;
  padding: 5px 0px;
  margin-bottom: 5px;
  max-width: 230px;
  width: 100%;
  text-transform: uppercase;
}

.games {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 8px;
  border-radius: 16px;
  background: #1e2134;
  padding: 16px;
}

.singleGameSection {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  padding: 8px 0;
  border-bottom: 1px solid #2C386E;
}

.buttonStyles {
  background: transparent linear-gradient(180deg, #06bbf7 0%, #163d75 100%) 0% 0% no-repeat padding-box;
  border: 3px solid #06b9f3;
  height: 48px;
  border-radius: 10px;
  color: #ffffff;
  cursor: pointer;
}

.disabledButtonStyles {
  background: #4f5268 0% 0% no-repeat padding-box;
  border-radius: 10px;
  height: 48px;
  color: #000;
}

@media only screen and (max-width: 400px) {
  .gameSection {
    max-width: 150px;
  }
}