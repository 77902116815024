.mainWrapper{
  /* margin : 60px 0; */
display: flex;
flex-direction: column;
gap: 50px;

padding: 100px 0;
/* background-image: url("../../../assets/HomeImages/BG\ -\ After\ login.png"); */
}
.topImg > img{
    object-fit: contain;
    width: 100%;
}
.downImg > img{
  object-fit: contain;
  width: 100%;
}

.sectionOverlay {
  width: 100%;
}

.sectionWraper {
  display: flex;
  flex-direction: column;
  /* padding: 0 8%; */
}

.imageWrapper {
  display: flex;
  justify-content: space-evenly;
  color: var(--goldColor);
  text-align: center;
}

.imageWrapper div img {
  transition: transform .5s ease-in;
}

.imageWrapper div img:hover {
  transform: translateX(40px);
}

@media only screen and (max-width: 1440px) {
  .sectionWraper {
    /* padding: 0 2%; */
  }
}