.modalOverlay {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalWrapper {
  width: 455px;
  max-height: 500px;
  padding: 10px 0px;
  overflow: auto;
  background: var(--modalBkg);
  border: 1px solid var(--lightColor);
  border-radius: 7px;
}

.modalContent {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.closeIcon {
  text-align: right;
  padding-right: 10px;
}

.icon {
  cursor: pointer;
  font-size: 22px;
  border-radius: 50%;
  padding: 2px;
  color: var(--lightColor);
  background: var(--lightBtn);
}

.icon:hover {
  background: var(--activeBtn);
  /* color: var(--lightColor); */
}

.innerContent {
  flex-grow: 1;
  padding: 0px 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: var(--lightColor);
}

.innerContent img {
  border: 1px solid var(--lightColor);
  border-radius: 7px;
}

.innerContent span {
  color: var(--lightColor) !important;
}

.title {
  font-size: 1.3rem;
}

.dates {
  font-size: 0.8rem;
}

.dates table tbody tr td {
  border: 1px solid var(--lightColor) !important;
}

.dates p {
  background: transparent !important;
}

@media only screen and (max-width: 414px) {
  .modalWrapper {
    width: 95%;
    padding: 5px 0;
  }

  .icon {
    font-size: 18px;
  }

  .modalContent {
    gap: 5px;
  }

  .innerContent {
    padding: 0px 7px;
  }

  .innerContent span {
    font-size: 14px;
  }

  .title {
    font-size: 1.1rem !important;
  }
}

