.wrapper {
    width: 100%;
    /* padding: 50px 0 ; */
    display: flex;
    gap: 80px;
    align-items: center;

}

.imgWrapper {
    flex-basis: 50%;
    /* width: 800px; */
    /* height: auto; */
}

.imgWrapper>img {
    width: 100%;
    height: auto;
    object-fit: contain
}

.textContent {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-basis: 50%
}

.textContent>span:nth-child(1) {
    font-style: normal;
    font-variant: normal;
    font-weight: bold;
    font-size: 30px;
    /* line-height: 22px; */
    font-family: 'SVN-Days', sans-serif;
    color: #F7DE6B;
}

.textContent>span:nth-child(2) {
    font-style: normal;
    font-variant: normal;
    font-weight: medium;
    font-size: 18px;
    line-height: 30px;
    font-family: 'Roboto', sans-serif;
    color: #FFFFFF;
}

@media screen and (max-width: 1200px) {}