.bankAccountWrapper {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.noAccountWrapper {
  /* width: 400px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 145px;
  /* max-width: 400px; */
}

.allBanksOverlay {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}

.errorMsg {
  font-size: 16px;
  text-align: center;
}

.banksSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}

.bankCardWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 8px;
}

.bankCard {
  color: var(--lightColor);
  padding: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  /* border: 2px solid var(--primaryColor); */
  /* background: #393d64 0% 0% no-repeat padding-box;
  border-radius: 10px; */
  border-radius: 8px;
  background: linear-gradient(180deg, #212a43 0%, #2d3970 100%);
}

.accountNum {
  font-size: 16px;
  font-weight: 500;
}

.username {
  font-size: 12px;
}

.bankCardContent {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.bankCard:hover {
  background: transparent linear-gradient(90deg, #333d54 0%, #4d576e 100%);
}

.deleteBankImage {
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 18px;
}

.deleteBankImage img {
  width: 18px;
  cursor: pointer;
}

.addBnksBtn {
  text-align: center;
  width: 100%;
  padding: 10px;
  color: var(--lightColor);
  cursor: pointer;
  background: transparent;
  /* background: transparent linear-gradient(180deg, #333755 0%, #040619 100%) 0%
    0% no-repeat padding-box;
  border: 1px solid #ffffff;
  border-radius: 24px; */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-family: "Roboto", sans-serif;
}

.supportStyles {
  padding: 0 20px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: column;
  width: 367px;
}

.textStyles {
  font-size: 14px;
  color: #fff;
  text-align: center;
}

.supportBtn {
  border-radius: 8px;
  border: 1px solid #fff;
  background-color: transparent;
  min-width: 115px;
  height: 40px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
}

.horizontalLine {
  width: 100%;
  height: 1px;
  background: #3f519f;
}

.addBnksBtn:hover {
  /* background: var(--activeBtnHover); */
}

.addBnksBtn.disabled {
  color: gray;
  cursor: not-allowed;
}
