.forgot-password-page {
  background: url('../../assets/ForgotPasswordBackground.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  align-content: center;
  text-align: center;
}

.forgot-password-container {
  position: relative;
  display: grid;
  z-index: 10;
  gap: 24px;
  border-radius: 16px;
  border: 2px solid #3F519F;
  background: rgba(44, 56, 110, 0.50);
  backdrop-filter: blur(17.5px);
  color: #FFFFFF;
  padding: 40px;
  margin: 0px auto;
  font-size: 24px;
  width: 551px;
  height: 364px;
}

.phone-icon {
  position: absolute;
  display: flex;
  top: -7px;
  left: 20px;
  height: 100%;
  align-items: center;
  z-index: 10;
}

.input-number {
  background: #2C386E;
  border: 1px solid #3F519F;
  border-radius: 8px;
  color: #fff;
  font-size: 16px;
  height: 56px;
  padding: 1px 16px 1px 50px;
}

.otp {
  padding: 1px 16px;
}

.otp-timer {
  color: #00C1FE;
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 8px;
}

.resend-otp {
  color: #737797;
  cursor: not-allowed;
  font-weight: 700;
  padding-left: 8px;
}

.active {
  color: #00C1FE;
  cursor: pointer;
}

.input-placeholder {
  font-size: 16px;
}

.input-description {
  color: #FFBA68;
  font-size: 20px;
  font-weight: 700;
  padding-top: 3px;
  text-align: left;
}

.input-error {
  color: #FD5656;
  font-size: 16px;
  font-weight: 600;
  width: 482px;
  text-align: left;
}

.otp-button {
  background: linear-gradient(180deg, #06BBF7 0%, #17346C 100%);
  color: #fff !important;
  border-radius: 8px !important;
  height: 50px !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  text-transform: none !important;
  margin-top: -15px !important;
  pointer-events: all !important;
}

.otp-button:hover {
  background: linear-gradient(0deg, #06BBF7 0%, #06BBF7 100%);
  color: #fff;
}
