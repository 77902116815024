.depositInputWrapper {
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: flex-start; */
  gap: 5px;
  width: 100%;
}

.depositInputContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
  /* border-bottom: 1px solid var(--pageBorder); */
}

.requiredLabel {
  gap: 3.7px;
  /* font-weight: 600; */
  min-width: 80px;
  font-size: 12.5px;
  padding-bottom: 3.7px;
}

.requiredLabel span {
  color: #c79353;
  /* font-size: 20px; */
}

.inputWrapper {
  position: relative;
  flex-grow: 1;
  border-radius: 8px;
  border: 1px solid #3f519f;
  background: #2c386e;
  padding: 10px;
  position: relative;
}

.depositInputContainer input {
  margin-left: 7.4px;
  flex-grow: 1;
  font-size: 12.5px;
  color: var(--lightColor);
  background-color: transparent;
  border: 0;
  width: 90%;
  padding: 0;
}

.inputAmoutRequired {
  font-size: 12px;
  color: #FF9A21;
  font-weight: 600;
}

.depositInputContainer input:focus,
.depositInputContainer input:hover,
.depositInputContainer input:active,
.depositInputContainer input:focus-visible {
  outline: none;
  border: none;
}

.cancelIcon {
  display: flex;
  justify-content: center;
  font-size: 22px;
  cursor: pointer;
  min-width: 25px;
  position: absolute;
  right: 4px;
  bottom: 10px;
  z-index: 100;
}

/* tooltip css */

.tooltipWrapper {
  position: absolute;
  width: 100%;
  bottom: 30px;
  left: 0px;
}

.tooltip {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border: 1px solid #ff5757;
  width: 81.2px;
  height: 26px;
  background-color: #4d000a;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
}

.tooltip span {
  background-color: #4d000a;
  color: #ff6e68;
  text-align: center;
  border-radius: 6px;
}

.arrowDown {
  left: 3%;
  top: 80%;
  position: absolute;
}

.arrowDown img {
  width: 12px;
}