.BankDetailsSection {
  width: 443px;
  padding: 11px 0 0 15px;
}

.errorMsg {
  color: var(--redColor);
  text-align: center;
  font-size: 1.2rem;
  font-weight: 500;
}

.companyBankSection {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: center;
}

.depositGuide {
  font-size: 12.5px;
  text-align: left;
  width: 100%;
}

.userInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.suggestMsg {
  font-size: 13px;
  text-align: left;
}
.qrCodeContainer {
  width: auto;
  display: flex;
  align-items: end;
  gap: 40px;
  margin: 0 auto;
}
.companyLogoWrapper {
  width: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}


.imageContainer {
  max-width: 100px;
  max-height: 60px;
  width: 100%;
  height: 100%;
}
.downloadIntruction {
  text-align: left;
  font: normal normal bold 15px/24px Roboto;
  letter-spacing: 0px;
  color: #FF5746;
  opacity: 1;
}
.download_button {
  margin-top: 5px;
  color: white;
  text-decoration: none;
  background-color: #1f2738;
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
}


.buttonStyle {
  max-width: fit-content;
  width: 100%;
  height: 38px;
  background: #19253F 0% 0% no-repeat padding-box;
  border-radius: 7px;
  opacity: 1;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  gap: 5px;
}


.downloadText {
  text-align: left;
  font: normal normal normal 12px/18px Roboto;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}


.qrcodeImgContainer {
  max-width: 140px;
  max-height: 140px;
  width: 100%;
  height: 100%;
}


.DetailContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}


.titleLabel {
  text-align: left;
  font: normal normal medium 20px/25px Roboto;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  margin-bottom: 10px;
}
.dottedWrapper{
  display:flex;
   justify-content:space-between ;
   width: 100%;
  border-bottom: 3px dashed #707070;


}
.detailWrapper {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* align-items: center; */
  padding-bottom: 5px;
}


.companyBankSection {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: center;
}


.singleBankItem {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}


.singleBankItem img {
  width: 100px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid var(--lightColor);
}


.selectedBank img {
  border-color: var(--goldColor);
}


.goldMessage {
  font-weight: bold;
  color: var(--goldColor);
  text-align: center;
  /* padding: 20px 0; */
}


.userInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.suggestMsg {
width: 100%;
}
.suggestMsg p {
  text-align: left;
  font: normal normal normal 18px/26px Roboto;
  letter-spacing: 0px;
  color: #FFFFFF;
  margin: 0;
  font-size:16px;
}


.completedButton {
  width: 100%;
  height: 50px;
  background: transparent linear-gradient(180deg, #2B3F66 0%, #19253F 100%) 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 4px 12px #141E331A;
  border: 1px solid #FFFFFF;
  border-radius: 25px;
  opacity: 1;
  font: normal normal medium 20px/24px Roboto;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: capitalize;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}






@media only screen and (max-width: 1440px) {
  .leftSection {
    width: 45%;
  }
}