.maxMinWrapper {
  display: flex;
  justify-content: space-between;
  color: var(--lightColor);
}

.columnView {
  flex-direction: column;
}

.maxMinValue {
  color: #00c1fe;
  font-size: 18px;
  font-weight: bold;
}

.invalidValue {
  color: var(--redColor);
}