.walletSection {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.gameSectionStyles {
  /* background: #1e2134 0% 0% no-repeat padding-box; */
  /* border-radius: 14px; */
  height: 400px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-height: 500px;
  overflow-y: auto;
  background: #1e2134;
  padding: 16px;
  border-radius: 16px;
}

.horizontalLine {
  width: 100%;
  height: 1px;
  background: #3f519f;
  margin: 10px auto;
}

.gameSection {
  color: #fff;
  width: 100%;
  text-transform: uppercase;
}

.games {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 16px;
}

.singleGameSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
}

.singleGameValue {
  display: flex;
  align-items: center;
}

.buttonStyles {
  background: linear-gradient(180deg, #00C1FE 0%, #163D75 100%);
  border: 1px solid #06B9F3;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  height: 48px;
  max-width: 320px;
  width: 100%;
  margin: 0 auto;
}

.buttonStyles:hover {
  background: linear-gradient(0deg, #06BBF7 0%, #06BBF7 100%);
  color: #fff;
}

.disabledButtonStyles {
  background: #737797;
  border-radius: 8px;
  border: none;
  color: #2C386E;
  cursor: not-allowed;
  height: 48px;
  max-width: 320px;
  width: 100%;
  margin: 0 auto;
}

@media only screen and (max-width: 400px) {
  .gameSection {
    max-width: 150px;
  }
}