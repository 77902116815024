.stepSection {
  display: flex;
  justify-content: center;
  align-self: center;
  width: 100%;
  border-bottom: 1px solid #3f519f;
  padding-bottom: 20px;
  position: relative;
}

.stepText {
  color: #fff;
  font-weight: 400;
}
