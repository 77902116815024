@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700&display=swap');

.MuiButtonBase-root.MuiTab-root {
  color: var(--headerFooterColor);
  padding-bottom: 0px;
  font-size: 12.5px;
  font-weight: 500;
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.MuiTab-fullWidth.Mui-selected {
  color: var(--primaryColor);
}

.css-3bmhjh-MuiPaper-root-MuiPopover-paper {
  background-color: transparent !important;
  box-shadow: none !important;
}

.css-ascpo7-MuiStepLabel-root {
  display: flex !important;
  flex-direction: column !important;
  gap: 10px !important;
}

.grecaptcha-badge {
  display: none;
}

.MuiSvgIcon-root {
  path {
    color: white;
  }
}