.modalOverlay {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.modalWrapper {
  width: 18%;
  /* max-height: 600px; */
  padding-bottom: 25px;
  border: 1px solid var(--pageBorder);
  border-radius: var(--modalRadius);
  background: var(--modalBkg);
}

.ImageCard {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ImageCardHeader {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ImageCardHeader p {
  margin: 10px 0;
  color: var(--lightColor);
  font-weight: 700;
  font-size: 1.3rem;
}

.icon {
  cursor: pointer;
  font-size: 20px;
  border-radius: 50%;
  padding: 2px;
  color: var(--lightColor);
}

.icon:hover {
  background: var(--disableBkg);
  color: var(--lightColor);
}

.ImageCard img {
  border-radius: 10px;
  height: 100%;
  width: 90%;
  border: 1px solid var(--pageBorder);
}
