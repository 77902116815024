.modalTopOverlay {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: absolute;
    /* width: 100%; */
  }
  .wrapper{
    position: relative;
    border: none;
    outline: none;
    width: 80vw;
    max-width: 400px ;
  }
  .wrapper > img{
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  .crossIcon{
    position: absolute;
    top:10px;
    right:10px;
    cursor: pointer;
  }
  @media only screen and (max-width: 414px) {
    .wrapper{
      width: 370px;
      }
    }
    @media only screen and (max-width: 375px) {
      .wrapper{
        width: 330px;
        }
      }
      @media only screen and (max-width: 345px) {
        .wrapper{
          width: 300px;
          }
        }
        @media only screen and (max-width: 300px) {
          .wrapper{
            width: 250px;
            }
          }
  @media only screen and (max-width: 280px) {
    .wrapper{
    width: 220px;
    }
  }