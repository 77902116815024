.modalOverlay {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalWrapper {
    width: 400Px;
  max-height: 50%;
  min-height: 400px;
  overflow: auto;
  color: var(--lightColor);
 background: #19253F 0% 0% no-repeat padding-box;
border: 1px solid #FFFFFF;
border-radius: 13px;
padding: 30px 20px;
}
.modalMainWrapper{
  position: relative 	;
  border: none;
  background-color: transparent;
  outline: none;
}
.modalWrapper:focus {
  outline: none;
}
.modalMainWrapper > .modalWrapper > span{
 position: absolute;
  top:-33px;
  right:10px;
  cursor: pointer;
  background-color: gray;
  display: flex;
  justify-content: center;
  align-items: center;
}
.titleWrapper{
        border-bottom: 1px solid #DCF6FE ;
}
.titleWrapper > p {
    margin: 0;
    padding: 0 0 10px 0;
}
.smallContent{
padding: 15px 0;
}
.smallContent >span{
    font: normal normal normal 14px/24px Roboto;
letter-spacing: 0px;
color: #FBDF71;
}

.inputWrapperDiv{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 10px;
}

.requiredLabel {
  display: flex;
  gap: 5px;
  font-weight: 600;
  font-size: 14px;
}

.requiredLabel span {
  color: #C79353;
  font-size: 20px;
}
.formInput {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: transparent;
  text-align: left;
  border-bottom: 1px solid #707070;
}

.formInput input {
  width: 100%;
  font-size: 16px;
  border: none;
  padding: 5px 0 5px 5px;
  color: #FFFFFF;
  background-color: transparent;
}

.formInput input:focus {
  outline: none;
}

.formInput input::placeholder {
  color: var(--inputPlaceholder);
  opacity: 50%;
}
.inputPasswordWrapper {
  display: flex;
  position: relative;
}

.inputPasswordWrapper input {
  width: 88%;
  margin: 0 auto 0 0;
}

.passwordEye {
  position: absolute;
  right: 5px;

  bottom: 5px;
}



.btn {
    margin-top: 30px;
    width: 100%;
  cursor: pointer;
  color: #DCF6FE;
  font-size: 19px;
  font-weight: normal;
  padding: 12px 0;
 background: transparent linear-gradient(180deg, #19253F 0%, #0A1326 100%) 0% 0% no-repeat padding-box;
box-shadow: inset 0px 4px 12px #141E331A;
border: 1px solid #DCF6FE;
border-radius: 25px;
}

/* .btn:hover {
  background: var(--activeBtnHover);
} */

.btn:disabled,
.btn[disabled] {
  cursor: not-allowed;
  font-size: 18px;
  font-weight: bold;
  padding: 12px 0;
  border-radius: 25px;
  border: 1px solid transparent;
  box-shadow: none;
  background: #CCCCCC !important;
  color:#666666 !important;
  /* height: 45px */
}
.error {
  color: var(--redColor);
  font-size: 0.8rem;
}