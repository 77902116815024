.loginButtonsOverlay {
  display: flex;
  gap: 10px;
  padding: 5px;
  box-sizing: border-box;
  max-width: 250px;
  width: 100%;
  position: relative;
}

.loginButtonsOverlay button {
  display: flex;
  width: 152px;
  height: 40px;
  padding: 8px 0px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  cursor: pointer;
}

.loginButtonsOverlay button:nth-child(1) {
  border: 1px solid #fef01c;
  background: linear-gradient(0deg, #f9a410 0%, #fff21c 100%);
  color: #000;
}

.loginButtonsOverlay button:nth-child(1):hover {
  background: #fff21c;
}

.loginButtonsOverlay button:nth-child(2) {
  border-radius: 8px;
  border: 1px solid #fef01c;
  color: #fff;
  background: transparent;
}

.loginButtonsOverlay button:nth-child(2):hover {
  /* background: ; */
}
