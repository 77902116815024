.header1 {
  background-color: #1e2134;
  height: 54px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  padding: 0 100px;
  color: #fff;
  position: relative;
  z-index: 6;
}

.balanceSection {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 14px;
  min-width: 90px;
  height: 30px;
  /* background: #1f2133 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 99px #000000; */
  border-radius: 7px;
  padding: 0 5px;
  cursor: pointer;
}

.accountStyles {
  display: flex;
  align-items: center;
  gap: 10px;
}

.avatarStyles {
  width: 38px !important;
  height: 38px !important;
}

.balanceStyles {
  font-weight: 500;
  font-size: 18px;
}

.balanceAmountStyles {
  font-weight: 500;
  font-size: 18px;
  color: #00c1fe;
}

.transferSection {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 14px;
  min-width: 90px;
  height: 30px;
  background: #1f2133 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 99px #000000;
  border-radius: 7px;
  padding: 0 5px;
}

.transferButton {
  background: transparent linear-gradient(180deg, #e9fef7 0%, #15c7fe 100%) 0%
    0% no-repeat padding-box;
  border-radius: 5px;
  height: 20px;
  width: 70px;
  border: 0;
  font-size: 12px;
  cursor: pointer;
}

.loginButton {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  width: auto;
}

.logoutBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 12px;
  width: 90px;
  height: 30px;
  cursor: pointer;
  background: transparent linear-gradient(180deg, #333755 0%, #040619 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 0px 99px #000000;
  border: 1px solid #ffffff;
  border-radius: 7px;
  color: #fff;
}

.iconSize {
  height: 20px;
  width: 20px;
}

.iconSize2 {
  height: 25px;
  width: 25px;
}

.linkWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.siteLogo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  width: 157px;
  height: 45px;
}
.siteLogoImage {
  /* height: 100%; */
  width: 143px;
  object-fit: contain;
}

.header1_links {
  color: white;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
}
.header1_links:hover {
  cursor: pointer;
}
.header1_icons {
  height: 15px;
  width: 15px;
}

/* Responsiveness */
@media only screen and (max-width: 1280px) {
  .header1 {
    gap: 7.4px;
    padding: 0 44px;
  }
}
