.depositSectionOverlay {
  width: 100%;
  max-width: 400px;
  /* border-right: 1px solid var(--pageBorder); */
}

.depositSectionWrapper {
  padding-right: 37px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.depositButtonSection {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.depositButton {
  padding: 4px 16px;
  font-size: 12.5px;
  cursor: pointer;
  color: white;
  border-radius: 8px;
  border: 1px solid #3f519f;
  background: linear-gradient(180deg, #212a42 0%, #2d3970 100%);
}

.horizontalLine {
  width:350px;
  height: 1px;
  background: #3f519f;
  margin: 24px auto;
}

.paymentMethodWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.paymentMethodTitle {
  font-size: 16px;
  margin-bottom: 8px;
}

.bankSectionContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.bankSectionTitle {
  font-size: 14px;
  margin-bottom: 8px;
}

.bankList {
  margin-top: 15px;
  width: 100%;
  display: flex;
  gap: 7.5px;
  justify-content: flex-start;
  align-items: center;
}

.singleBankItem {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding: 3.7px;
  border-radius: 5px;
}

.active {
  border: 2px solid var(--primaryColor);
}

.singleBankItem img {
  width: 103px;
  height: 52px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid var(--lightColor);
}

.submitButton {
  margin-top: 10px !important;
  padding: 7.4px 0px;
  font-size: 15px;
  cursor: pointer;
  width: 100%;
  color: var(--lightColor);
  height: 45px;
  max-width: 160px;
  margin: 0 auto;
  border-radius: 8px;
  border: 1px solid #00c1fe;
  background: linear-gradient(180deg, #06bbf7 0%, #17346c 100%);
}

.loader {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.submitButton.disabled {
  border-color: var(--disableBkg);
  background: var(--disableBkg);
  color: #2C386E;
  cursor: not-allowed;
  background: #737797;
}

.errorMsg {
  display: flex;
  justify-content: flex-start;
  align-self: flex-start;
  color: var(--redColor);
  font-size: 0.85rem;
  font-weight: 500;
  margin: 6px 0;
}

.companySelectionContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.label {
  width: 100%;
  text-align: left;
  font: normal normal normal 20px/28px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.companySelection {
  margin-top: 10px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  overflow: auto; 
  scrollbar-color: white #2c386e;
  scrollbar-width: thin;
  padding-bottom: 10px;
}

::-webkit-scrollbar {
  width: 8px; 
  height: 8px; 
}



.methodCard {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  background: linear-gradient(0deg, #1E2134 0%, #1E2134 100%);
  border-radius: 16px;
  min-width: 180px;
  width: auto;
  height: 80px;
  cursor: pointer;
}

.activeMethod {
  border-radius: 16px;
  border: 1px solid#00C1FE;
  background: linear-gradient(0deg, #163D75 0%, #163D75 100%);
}

.methodCard img {
  object-fit: contain;
  width: auto;
  height: auto;
}

.companyBankSection {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.companyBankContainer {
  width: 100%;
  display: flex;
  align-items: start;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: center;
}

.methodCardSection {
  display: flex;
  justify-content: space-evenly;
  gap: 10px;
  margin: 10px 0px;
  flex-wrap: wrap;
}

.companySingleBankCard {
  /* width: 100%; */
  height: 100%;
  display: flex;
  align-items: center;
  background: transparent;
}

.activeSelectedBank {
  border: 2px solid #fee971;
}

.imageContainer {
  width: 60px;
  height: 55px;
}

.imageContainer>img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media screen and (max-width: 1280px) {
  .depositButton {
    padding: 4px 15px;
    font-size: 12px;
  }
}