.WithdrawBalanceWrapper {
  display: flex;
  gap: 22px;
}

.withdrawOverlay {
  width: 450px;
  display: flex;
  flex-direction: column;
  gap: 7.4px;
}

.balanceLabel {
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 22px;
  font-weight: 400;
}

.balanceLabel span:nth-child(1) {
  color: var(--goldColor);
}

.balanceLabel span:nth-child(2) {
  color: var(--lightColor);
}

.errorMsg {
  color: var(--redColor);
  font-size: 0.85rem;
  font-weight: 500;
  margin: 6px 0;
}

.backBtn {
  font-size: 16px;
  display: flex;
  align-items: left;
  justify-content: center;
  width: 100%;
  margin-top: 1.5rem;
  cursor: pointer;
  padding: 10px 0px;
  background-color: transparent;
  color: #00c1fe;
  border-radius: 8px;
  border: 1px solid #00c1fe;
}

.withdrawBtn {
  font-size: 16px;
  display: flex;
  align-items: left;
  justify-content: center;
  width: 100%;
  margin-top: 1.5rem;
  cursor: pointer;
  padding: 10px 0px;
  color: var(--lightColor);
  /* background: transparent linear-gradient(180deg, #333755 0%, #040619 100%) 0%
    0% no-repeat padding-box;
  border: 1px solid #ffffff;
  border-radius: 24px; */
  border-radius: 8px;
  border: 1px solid #00c1fe;
  background: linear-gradient(180deg, #06bbf7 0%, #17346c 100%);
}
/* 
.withdrawBtn:hover {
  background: var(--activeBtnHover);
} */

.disabled {
  box-shadow: none;
  color: var(--disableColor);
  cursor: not-allowed;
  background: var(--disableBkg);
  border: none;
}

.disabled:hover {
  background: var(--disableBkg);
  color: var(--disableColor);
}
.BankSectionOverlay {
  width: 100%;
}

.noBanksMsg {
  color: var(--redColor);
  font-size: 16px;
}

.BankSectionWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 14px;
  width: 100%;
}

.bankCardWrappwr {
  font-size: 12px;
  cursor: pointer;
  width: 100%;
  display: flex;
}

.bankCard {
  align-items: center;
  display: flex;
  width: 100%;
  height: 52px;
  padding: 10px;
  text-align: left;
  color: white;
  border-radius: 8px;
  background: linear-gradient(180deg, #212a43 0%, #2d3970 100%);
}

.bankCard:hover {
  background: #393d64 0% 0% no-repeat padding-box;
  border-radius: 10px;
  border: 2px solid var(--primaryColor);
}

.selectedBank {
  border: 2px solid var(--primaryColor);
  background: #393d64 0% 0% no-repeat padding-box;
  border: 2px solid var(--primaryColor);
  border-radius: 5px;
  color: var(--primaryColor);
}

.bankCardContent {
  gap: 5px;
  display: flex;
  flex-direction: column;
}

.supportStyles {
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}

.textStyles {
  font-size: 14px;
  color: #fff;
  text-align: center;
}

.supportBtn {
  border-radius: 8px;
  border: 1px solid #fff;
  background-color: transparent;
  min-width: 115px;
  height: 40px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
}

.buttons {
  display: flex;
  gap: 10px;
}

.AddBankBtnWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 125.5px;
  height: 52px;
  color: var(--lightColor);
  cursor: pointer;
  background: transparent linear-gradient(180deg, #202f4e 0%, #10192d 100%);
  border-radius: 10px;
  padding: 10px;
  border: 2px solid var(--disableColor);
}

.AddBankBtnWrapper:hover {
  background: transparent linear-gradient(180deg, #10192d 0%, #202f4e 100%);
}

.AddBankBtn {
  gap: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 12px;
  align-items: center;
}
.bankGuideText {
  font-size: 15px;
}
.addBankImage {
  width: 22px;
  height: 22px;
}
