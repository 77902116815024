.agencyRegisterWrapper {
  height: 100%;
  display: flex;
  justify-content: space-between;
  /* background: url('../../assets/LoginIcons/LoginBg.webp');
  background-size: cover; */
  background: rgba(30, 33, 52, 0.65);
  backdrop-filter: blur(17.5px);
  border-radius: 16px;
}

.logoImageWrapper {
  display: flex;
  align-items: center;
  gap: 30px;
  border-radius: 16px;
}

.chickenImageContainer {
  width: 300px;
  height: 670px;
  position: relative;
}

.logoStyles {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 230px;
}

.chickenImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.logoImageWrapper img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.formOverlay {
  width: 500px;
  height: 670px;
  overflow-y: auto;
  /* margin: 0 15% 0 0;
  background: var(--formBkg);
  border: 1px solid var(--pageBorder);
  border-radius: var(--fromBorderRadius);
  box-shadow: var(--formShadow); */
}

.formWrapper {
  padding: 20px;
  color: var(--lightColor);
}

.formWrapper form {
  display: flex;
  flex-direction: column;
}

.agencyRegisterFormHeader {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 1.2em;
  border-bottom: 1px solid #2c386e;
  padding-bottom: 10px;
}

.agencyRegisterFormBody {
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.agentIdWrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.agentIdWrapper span:nth-child(1) {
  font-size: 12px;
}

.agentIdWrapper span:nth-child(2) {
  font-weight: bold;
}

.requiredLabel {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  padding-bottom: 5px;
}

.requiredLabel span {
  color: #f9a410;
  font-size: 20px;
}

.formInput {
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #3f519f;
  background: #2c386e;
  padding-left: 24px;
  position: relative;
}

.leftIconStyles {
  position: absolute;
  height: 100%;
  left: 10px;
  display: grid;
  place-items: center;
}

.formInput input {
  width: 100%;
  font-size: 16px;
  border: none;
  padding: 15px 0 15px 20px;
  color: var(--lightColor);
  background-color: transparent;
}

.formInput input:focus,
.formInput input:active,
.formInput input:focus-within {
  border: none;
  outline: none;
}

.formInput input::placeholder {
  color: var(--lightColor);
  opacity: 0.3;
}

.error {
  color: var(--redColor);
  font-size: 0.9rem;
  text-align: center;
  display: flex;
  align-items: center;
}

.passwordChecksMsg {
  text-align: left;
  font-size: 12px;
  font-style: italic;
  margin: 0 0 5px 0;
  color: var(--goldColor);
}

.passwordChecksMsg span {
  color: #41df60;
  font-weight: 900;
}

.formInput img {
  cursor: pointer;
  margin-right: 15px;
  width: 20px;
}

.passwordValidation {
  display: flex;
  gap: 3px;
  padding-top: 3px;
}

.passwordValidation span {
  display: flex;
  align-items: center;
}

.success {
  color: #41df60;
  font-size: 0.9rem;
}

.agencyRegisterSubmitButton {
  margin-top: 10px;
  font-size: 1rem;
  padding: 15px 0px;
  cursor: pointer;
  width: 100%;
  color: var(--lightColor);
  border-radius: 8px;
  border: 1px solid #06b9f3;
  background: linear-gradient(180deg, #00c1fe 0%, #163d75 100%);
}

.horizontalLine {
  width: 100%;
  height: 1px;
  background: #2c386e;
}

.agencyRegisterSubmitButton:hover {
  background: var(--activeBtnHover);
}

.loading,
.loading:hover {
  border-color: var(--disableBkg);
  color: var(--disableColor);
  background: var(--disableBkg);
  cursor: not-allowed;
}

.loginSection {
  font-weight: 600;
  text-align: center;
}

.loginLink {
  margin-left: 0.4rem;
  cursor: pointer;
  text-decoration: underline;
}

@media only screen and (max-width: 1440px) {
  /* .formOverlay {
    width: 450px;
    margin: 0 5% 0 0;
  }

  .formWrapper {
    padding: 25px;
  } */
}

@media only screen and (max-width: 1024px) {
  /* .formOverlay {
    width: 400px;
  } */
}
