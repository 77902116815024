.transactionWrapper {
  width: 100%;
  display: flex;
  /* justify-content: flex-start; */
  flex-direction: column;
  /* align-items: center; */
}

.transactionContainer {
  width: 480px;
  /* border-right: 1px solid var(--pageBorder); */
  /* height: 410px; */
  overflow-y: auto;
  background: #1f2133 0% 0% no-repeat padding-box;
  border: 1.5px solid #737797;
  border-radius: 10px;
}

.transactionWrapper h3 {
  margin: 0px;
  text-align: center;
}

.transactionTable {
  padding: 30px 0 10px 0;
}

.transactionTable table {
  width: 100%;
  text-align: left;
}

.transactionTable table thead tr th {
  font-weight: 600;
  font-size: 15px;
  color: var(--primaryColor);
}
.transactionTable table tbody {
  padding-top: 7.4px;
}

.transactionTable table tbody tr {
  cursor: pointer;
  font-weight: 100;
  font-size: 13.5;
  margin: 4px 0;
}
.transactionTable table tbody tr td {
  padding: 4px;
}

.chip {
  text-align: center;
  display: flex;
  justify-content: center;
  font-weight: 600;
  padding: 4px 3px;
  width: 111px;
  border-radius: 7px;
  color: #94a9c6;
  border: 1px solid #94a9c6;
  background-color: #1a2b44;
}

.processingChip {
  color: #ffc36a;
  background-color: #482b00;
  border-color: #ffc36a;
}

.successChip {
  color: #00ffb5;
  background-color: #004430;
  border-color: #00ffb5;
}

.rejectChip {
  color: #ff5757;
  background-color: #4d000a;
  border-color: #ff5757;
}

.noTransaction {
  margin-top: 50px;
  max-width: 480px;
  text-align: center;
}

.closeTransaction {
  display: none;
}

.openTransaction {
  display: inline-block;
}