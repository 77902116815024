.popoverStyles {
  width: 450px;
  min-height: 300px;
  border-radius: var(--Sizes-Radius-radiius-medium, 16px);
  /* border: 2px solid var(--Colors-Stroke-colorStrokeInput, #3f519f); */
  background: rgba(30, 33, 52, 0.65);
  backdrop-filter: blur(17.5px);
  padding: 10px;
  color: white;
}

.notificationsOverlay {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  padding: 0px 0px;
}

.notificationsWrapper {
  display: flex;
  flex-direction: column;
  gap: 7px;
  width: 100%;
  padding: 15px;
  padding-bottom: 18px;
  box-sizing: border-box;
  /* cursor: pointer; */
  opacity: 1;
  height: 100%;
  border-radius: 15px;
}

.notificationRead {
  background: #1f2133 0% 0% no-repeat padding-box;
}

.notificationUnread {
  background: #38478b 0% 0% no-repeat padding-box;
}

.dividerStyle {
  width: 100%;
  margin: 0;
}

.dividerStyle hr {
  width: 95%;
  border: 1px solid #515151;
  margin: auto;
}

.titleStyling {
  text-align: left;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
  margin: 0;
  word-wrap: break-word; /* Allows long words to be able to break and wrap onto the next line */
  overflow-wrap: break-word; /* Ensures the text breaks in a way that prevents overflow */
  hyphens: auto; /* Automatically adds hyphens where appropriate for certain browsers */
  /* Ensure the element doesn't expand beyond its parent */
  max-width: 100%;
}

.titleDateContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.titleDateContainer p {
  text-align: right;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 36px;
  letter-spacing: 0px;
  color: #00c1fe;
  opacity: 1;
  margin: 0;
}

.detailStyling {
  text-align: left;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0px;
  color: #dcf6fe;
  opacity: 1;
  margin: 0;
  word-wrap: break-word; /* Allows long words to be able to break and wrap onto the next line */
  overflow-wrap: break-word; /* Ensures the text breaks in a way that prevents overflow */
  hyphens: auto; /* Automatically adds hyphens where appropriate for certain browsers */
  /* Ensure the element doesn't expand beyond its parent */
  max-width: 100%;
}

.noNotificationWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
  margin-top: 100px;
}

.noNotificationImageContainer {
  max-width: 108px;
  max-height: 130px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noNotificationWrapper h1 {
  font-size: 24px;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0px;
  color: #00c1fe;
  opacity: 1;
}

.textStyling {
  max-width: 320px;
  width: 100%;
  text-align: center;
  font-weight: normal;
  font-size: 16px;
  color: #737797;
  opacity: 1;
}

@media (max-width: 340px) {
  .titleDateContainer {
    display: inline;
  }

  .titleStyling {
    line-height: 15px;
  }

  .titleDateContainer p {
    line-height: 30px;
  }
}
