.requiredLabel {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  padding-bottom: 5px;
}

.requiredLabel span {
  color: #f9a410;
  font-size: 20px;
}

.captchaInput {
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #3f519f;
  background: #2c386e;
  padding-left: 24px;
  position: relative;
}

.leftIconStyles {
  position: absolute;
  height: 100%;
  left: 10px;
  display: grid;
  place-items: center;
}

.captchaInput input {
  width: 100%;
  font-size: 16px;
  border: none;
  padding: 15px 0 15px 20px;
  color: var(--lightColor);
  background-color: transparent;
}

.captchaInput input:focus {
  outline: none;
}

.captchaInput input::placeholder {
  color: var(--inputPlaceholder);
  opacity: 30%;
}

.captchaInput img {
  height: 32px;
  margin-right: 18px;
  border-radius: 2px;
}
