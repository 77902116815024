.SelectBankOverlay {
  /* border-bottom: 1px solid var(--pageBorder); */
}

.SelectBankWrapper {
  padding: 25px 0;
  display: flex;
  gap: 22px;
  justify-content: space-between;
}

.withdrawalContainer {
  width: 50%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: flex-start;
  gap: 15px;
  border-right: 1px solid #3f519f;
  padding-right: 24px;
}

.walletContainer {
  width: 50%;
}

.balanceSection {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 14px;
  min-width: 90px;
  /* background: #1f2133 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 99px #000000; */
  border-radius: 7px;
  cursor: pointer;
  margin-bottom: 8px;
}

.balanceStyles {
  font-weight: 500;
  font-size: 18px;
}

.balanceAmountStyles {
  color: #00C1FE;
  font-size: 32px;
  font-weight: 700;
}

.iconSize2 {
  height: 40px;
  width: 40px;
}

.balanceContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 22px;
  font-size: 15px;
}

.balanceLatest {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.balanceText {
  color: var(--primaryColor);
}

.inputDeposit {
  width: 100%;
}

.minMaxContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.minMaxSection {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7.4px;
}

.minMaxText {
  font-size: 14px;
  font-weight: 600;
}

.minMaxValue {
  color: #00C1FE;
  font-size: 14px;
  font-weight: 600;
  /* color: var(--primaryColor); */
}

.submitButton {
  margin-top: 10px !important;
  padding: 7.4px 0px;
  font-size: 15px;
  cursor: pointer;
  width: 100%;
  color: var(--lightColor);
  height: 45px;
  max-width: 160px;
  margin: 0 auto;
  border-radius: 8px;
  border: 1px solid #00c1fe;
  background: linear-gradient(180deg, #06bbf7 0%, #17346c 100%);
}

.submitButton.disabled {
  border-color: var(--disableBkg);
  background: var(--disableBkg);
  color: var(--disableColor);
  cursor: not-allowed;
  background: #737797;
}

@media only screen and (max-width: 1440px) {
  .BankSectionOverlay {
    width: 60%;
  }
}

@media only screen and (max-width: 1024px) {
  .BankSectionOverlay {
    width: 80%;
  }
}