.modalOverlay {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--modalOverlayBkg);
}

.loadingSection {
  width: 500px;
  text-align: center;
  padding: 30px 15px;
  margin: 10px;
  background: var(--modalBkg);
  border: 1px solid var(--pageBorder);
  border-radius: var(--modalRadius);
}

.messageText {
  color: var(--lightColor);
}

.errorImg {
  width: 60px;
  margin-bottom: 15px;
}

.rejectButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.okButton {
  margin-top: 10px;
  cursor: pointer;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 10px;
  width: fit-content;
}

.okButton {
  border: 1px solid var(--lightColor);
  color: var(--lightColor);
  width: auto;
  background: var(--activeBtn);

}

.okButton:hover {
  color: var(--lightColor);
  background: var(--activeBtn);
  border: 1px solid var(--lightColor);
}

.okButton:hover {
  background: var(--activeBtnHover);
}

.csBtn {
  border-color: var(--goldBtnBorderColor);
  background: var(--goldBtnBkg);
}

.csBtn:hover {
  background: var(--goldBtnBkgHover);

  background: var(--activeBtnHover);
}