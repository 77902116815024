.modalOverlay {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalWrapper {
  width: 660px;
  max-height: 80%;
  height: 374px;
  border-radius: 12px;
  background: #fff;
  border: none;
  padding: 0 0 20px;
  position: relative;
}

.modalWrapper:focus {
  outline: none;
}

.icon {
  cursor: pointer;
  border-radius: 50%;
  color: white;
  background: var(--formBkg);
  padding: 3px;
  width: 16px;
  height: 16px;
}

.icon:hover {
  opacity: 0.8;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--formBkg);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  padding: 10px 20px;
}

.title span {
  letter-spacing: 0.7px;
}

.contentWrapper {
  display: flex;
  flex-direction: row;
  padding: 10px 20px;
}

.leftSection {
  overflow: hidden;
  width: 192px;
  background: #fff;
  padding: 0 14px 10px 0;
  flex-shrink: 0;
  color: #000;
}

.leftContent {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid black;
  border-radius: 20px;
  color: var(--defaultColor);
  font-size: 13px;
  font-weight: 700;
  padding: 8px 0 8px 8px;
}

.round {
  background: var(--formBkg);
  border-radius: 100%;
  width: 12px;
  height: 12px;
}

.leftContent img {
  width: 16px;
  height: 16px;
}

.rightSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 7px;
  background: #f6f6f6;
  border-radius: 5px;
  color: #000;
  margin-left: 10px;
  height: 325px;
  max-width: 393px;
  overflow-y: auto;
  overflow-x: hidden;
}

.rightSection img {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
  height: auto;
  max-width: 100%
}

.rightContent {
  padding: 0 12px;
}
.rightContent h3 {
  text-transform: uppercase;
}

.rightContent p {
  margin-top: 0;
  margin-bottom: 0;
  padding: 2px;
  text-align: justify;
}