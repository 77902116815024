.stepOverlay {
  /* width: 120px; */
  /* width: max-content; */
  /* height: 225px; */
  width: 103px;
  height: 221.5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #1f2133 0% 0% no-repeat padding-box;
  border: 1px solid #40456e;
  border-radius: 5px;
  gap: 7.4px;
  font-size: 13px;
}

.stepImage {
  height: 30px;
  width: 30px;
}

.stepTitle {
  color: var(--primaryColor);
}
