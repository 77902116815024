.layoutOverlay {
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 100vh;
  overflow: auto;
}

.layoutPagesOverlay {
  overflow: auto;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
