.secondaryHeaderOverlay {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  color: white;
  background: #2c386e;
  /* box-shadow: 0px 3px 6px #00000029; */
  opacity: 1;
  /* box-shadow: 0px 1px 10px #00000099; */
  z-index: 4;
  height: 48px;
  padding: 0 100px;
  font-size: 14px;
  /* box-shadow: 0 4px 0 0 var(--primaryColor); */
  width: 100%;
  box-sizing: border-box;
}

.siteLogo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  width: 157px;
  height: 45px;
}

.siteLogoImage {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.gamesNavigationList {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  height: 100%;
  /* width: 100%; */
  /* margin-right: 25%; */
  /* margin: auto; */
}

.linkWrapper {
  display: flex;
  align-items: center;
  gap: 14px;
}

.header1_links {
  color: white;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  transition: 0.5s;

  &:hover {
    color: #06B9F3;
  }
}

.header1_links:hover {
  cursor: pointer;
}

.header1_icons {
  height: 15px;
  /* width: 15px; */
}

.game {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  height: 100%;
  cursor: pointer;
  gap: 4px;
}

.gameDaga {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
}

.divider {
  height: 50%;
  width: 1px;
  background: #fff;
}

.gameTitleDaga {
  font-size: 18px;
}

.gameImage {
  height: 30px;
}

.gameDagaImage {
  height: 40px;
  width: 120px;
}

.iconSize {
  height: 20px;
  width: 20px;
}

.iconSize2 {
  height: 25px;
  width: 25px;
}

.logoutBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 12px;
  width: 90px;
  height: 30px;
  cursor: pointer;
  background: transparent linear-gradient(180deg, #333755 0%, #040619 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 99px #000000;
  border: 1px solid #ffffff;
  border-radius: 7px;
}

.balanceSection {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 14px;
  min-width: 90px;
  height: 30px;
  background: #1f2133 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 99px #000000;
  border-radius: 7px;
  padding: 0 5px;
}

.transferSection {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 14px;
  min-width: 90px;
  height: 30px;
  background: #1f2133 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 99px #000000;
  border-radius: 7px;
  padding: 0 5px;
}

.transferButton {
  background: transparent linear-gradient(180deg, #e9fef7 0%, #15c7fe 100%) 0% 0% no-repeat padding-box;
  border-radius: 5px;
  height: 20px;
  width: 70px;
  border: 0;
  font-size: 12px;
  cursor: pointer;
}

.loginButton {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  width: auto;
}

/* Header links hot image animation */
@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-2px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-2px);
  }
}

/* Responsiveness */
@media only screen and (max-width: 1280px) {
  .secondaryHeaderOverlay {
    gap: 7.4px;
    padding: 0 44px;
  }
}