.modalOverlay {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--modalOverlayBkg);
}

.logoutOverlay {
  width: 350px;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  /* border: 1px solid var(--pageBorder);
  border-radius: 15px;
  background: var(--modalBkg); */
  border-radius: 16px;
  border: 1px solid #3f519f;
  background: linear-gradient(180deg, #1e2134 0%, #2c386e 100%);
}

.logoutImgwrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logoutImgwrapper p {
  margin: 0;
  color: white;
  font-size: 20px;
}

.logoutMsg {
  margin-top: 5px;
  color: var(--lightColor);
  text-align: center;
  font-size: 15px;
}

.logoutButtons {
  display: flex;
  gap: 15px;
}

.logoutButtons button {
  /* border-radius: 19px; */
  font-size: 14px;
  padding: 10px 22px;
  color: var(--lightColor);
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #06b9f3;
}

.logoutButtons button:nth-child(1) {
  /* border: 1px solid var(--pageBorder);
  background: var(--disableBkg);
  color: var(--disableColor); */
  background: linear-gradient(180deg, #00c1fe 0%, #163d75 100%);
}

.logoutButtons button:nth-child(2) {
  background-color: transparent;
  border: 1px solid #06b9f3;
}

.logoutButtons button:nth-child(1):hover {
  border-radius: 8px;
  border: 1px solid #06b9f3;
}

.logoutButtons button:nth-child(2):hover {
  background: var(--activeBtnHover);
}
