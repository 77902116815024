.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sectionTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 18px;
  width: 100%;
}

.title {
  text-align: center;
  font-size: 29px;
  font-weight: bold;
  letter-spacing: 0px;
  color: white;
  opacity: 1;
  min-width: fit-content;
  text-transform: uppercase;
}

.imgDivider {
  width: 100%;
  margin-bottom: -14px;
}

.sectionContent {
  display: flex;
  justify-content: center;
  gap: 25px;
  margin-top: 8px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.imageContainer {
  /* position: relative; */
  display: inline-block;
}

.overlayButton {
  position: absolute;
  top: 50%;
  left: 50%;
  cursor: pointer;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease;
  margin-top: 20px;
  font-size: 1rem;
  padding: 15px 30px;
  cursor: pointer;
  color: var(--lightColor);
  border-radius: 8px;
  border: 1px solid #06b9f3;
  background: linear-gradient(180deg, #00c1fe 0%, #163d75 100%);
}

.overlayButton:hover {
  background: var(--activeBtnHover);
}

.imageContainer:hover .dagaTitleImage {
  opacity: 0.6;
  background: #000;
}

.imageContainer:hover .overlayButton {
  opacity: 1;
}

.leftContent {
  background-image: url("../../assets/HomeImages/Section1/DAGA.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 445px;
  width: 55%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}

.dagaSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}

.dagaDescription {
  font-family: "Segoe UI";
  letter-spacing: 0px;
  color: var(--primaryColor);
  width: 17vw;
  text-align: center;
}

.dagaButton {
  background: transparent linear-gradient(180deg, #e9fef7 0%, #15c7fe 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 14px var(--primaryColor) b3;
  border: 1px solid #099fda;
  border-radius: 12px;
  width: 12vw;
  height: 5vh;
}

.dagaButtonText {
  text-align: center;
  font: normal normal bold 18px/22px Roboto;
  letter-spacing: 0px;
  opacity: 1;
  font-size: 11px;
}

.rightContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  width: 45%;
}

.hottestGameImage {
  height: 194px;
  width: 100%;
  cursor: pointer;
}

.dagaTitleImage {
  width: 100%;
  transition: opacity 0.3s ease;
  object-fit: contain;
  border-radius: 16px;
  border: 3px solid #FFFF6D;
  background: #33395C;
  box-shadow: 0px 273px 76px 0px rgba(0, 0, 0, 0.00), 0px 175px 70px 0px rgba(0, 0, 0, 0.04), 0px 98px 59px 0px rgba(0, 0, 0, 0.13), 0px 44px 44px 0px rgba(0, 0, 0, 0.22), 0px 11px 24px 0px rgba(0, 0, 0, 0.25);
}

@media only screen and (max-width: 1300px) {
  .dagaSection {
    gap: 7px;
  }

  .dagaTitleImage {
    height: 40px;
    object-fit: contain;
  }

  .dagaDescription {
    font-size: 11px;
  }

  .dagaButtonText {
    font-size: 11.8px;
  }

  /* .dagaButton {
    width: 100px;

    height: 25px;
  } */
}