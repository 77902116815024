.carousel-indicators [data-bs-target] {
    border-radius: 100%;
    width: 10px;
    height: 10px;
}

.errorMessage {
    text-align: center;
    padding: 20px 0;
    height: 150px;
    color: #ddd;
}