.modalOverlay {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--modalOverlayBkg);
}

.loadingSection {
  width: 300px;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 20px;
  padding: 30px 15px;
  background: var(--modalBkg);
  border: 1px solid var(--pageBorder);
  border-radius: 13px;
}

.loadingSection span {
  color: var(--redColor);
}

.errorImg {
  width: 60px;
  margin-bottom: 15px;
}

.rejectButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.rejectButtons button {
  cursor: pointer;
  font-weight: bold;
  padding: 10px;
  border-radius: 10px;
}

.gameButton {
  width: 110px;
  color: var(--goldColor);
  border: 1px solid var(--goldColor);
  background: var(--goldBtnBkg);
}

.gameButton:hover {
  background: var(--goldBtnBkgHover);
}

.okButton {
  background: var(--activeBtn);
  color: var(--lightColor);
  border: 1px solid var(--lightColor);
}

.okButton:hover {
  background: var(--activeBtnHover);
}