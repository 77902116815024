.headerGameList {
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 90px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 35px;
  background-color: #1a253e;
  z-index: 1;
  height: auto;
  top: -125px;
  opacity: 0;
  transition: top 0.5s ease, opacity 0.5s ease;
}

.open {
  top: 102px;
  opacity: 1;
}

/* .dagaRatio {
  position: absolute;
  top: 0px;
  left: 0;
  width: 120px;
  height: 24px;
  background: var(--dagaColor);
  clip-path: polygon(0 0, 100% 0, 76% 100%, 0 100%);
} */

.dagaRatio span {
  font-weight: bold;
  color: var(--darkColor);
  padding-left: 30%;
}

.otherGamesRatio {
  top: 0;
  position: absolute;
  right: 0;
  width: 120px;
  height: 24px;
  background: var(--headerFooterColor);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 23% 100%);
}

.otherGamesRatio span {
  padding-left: 50%;
  font-weight: bold;
  color: var(--darkColor);
}

.gameImgWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5.2px;
  transition: transform 0.3s ease;

  border-bottom: 3px solid transparent;
  /* transition: transform 0.3s ease; */
  /* width: 180px; */
  /* height: 210px; */
  width: 185px;
  height: 258px;
}
.imgWrap {
  height: 165px;
  width: 165px;
}
.gameImgWrapper img {
  /* cursor: pointer; */
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.gameImgWrapper span {
  color: white;
  font-size: 11px;
}

.gameImgWrapper button {
  cursor: pointer;
  color: #10192d;
  font-weight: bold;
  font-size: 9px;
  line-height: 18px;
  font-family: "Roboto", sans-serif;
  border: 0;
  width: 66.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent linear-gradient(180deg, #e9fef7 0%, #15c7fe 100%) 0%
    0% no-repeat padding-box;
  border-radius: 15px;
  font-weight: 600;
}
