.modalOverlay {
  height: 100%;
  width: 100%;
  /* position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--modalOverlayBkg); */
}

.modalWrapper {
  /* width: 500px;
  padding: 30px;
  background: transparent linear-gradient(131deg, #333755 0%, #06081b 100%) 0%
    0% no-repeat padding-box;
  border: 1px solid #ffffff;
  border-radius: 29px; */
}

.modalTitle {
  font-size: 14px;
  color: #fff;
}

.addAccountFormWrapper {
  /* margin-top: 20px; */
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.helptext {
  font-size: 9.6px;
  color: var(--lightColor);
  padding-left: 11px;
  font-weight: 600;
}

.helptext.danger {
  color: #ff5746;
}

.inputContainerWrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.inputContainerWrapper input {
  margin-left: 7.4px;
  flex-grow: 1;
  font-size: 12.5px;
  color: var(--lightColor);
  background-color: transparent;
  border: 0;
  width: 90%;
  padding: 0;
}

.inputAmountRequired {
  font-size: 14px;
  color: #FF9A21;
  margin-top: 8px
}

.requiredLabel span {
  color: #c79353;
}

.inputContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
}

.inputWrapper {
  position: relative;
  flex-grow: 1;
  border-radius: 8px;
  border: 1px solid #3f519f;
  background: #2c386e;
  padding: 10px;
  position: relative;
}


.inputContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2.2px;
}

.requiredLabel {
  display: flex;
  gap: 5px;
  color: #fff;
  font-size: 14px;
}

.requiredLabel span {
  color: #c79353;
  font-size: 13px;
}

.inputItem {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.inputItem input::placeholder {
  color: var(--inputPlaceholder);
  opacity: 50%;
}

.inputItem input {
  width: 100%;
  font-size: 16px;
  border: none;
  padding: 3.7px 0 3.7px 0;
  color: var(--lightColor);
  background-color: transparent;
}

.inputItem input:focus,
.inputItem input:hover,
.inputItem input:active,
.inputItem input:focus-visible {
  outline: none;
  border: none;
}

.addBankSelectWrapper {
  position: relative;
  width: 100%;
}

.addBankSelectWrapper select {
  cursor: pointer;
  font-family: "Be Vietnam Pro", sans-serif;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  font-size: 16px;
  border: none;
  padding: 3.7px 0 3.7px 0;
  color: var(--lightColor);
  background-color: transparent;
  border-bottom: 2px solid var(--pageBorder);
}

.addBankSelectWrapper span {
  position: absolute;
  right: 8px;
  top: -3px;
  color: var(--lightColor);
  font-size: 30px;
  cursor: pointer;
}

.addBankSelectWrapper select:focus {
  outline: none;
}

.addBankSelectWrapper select option {
  background-color: #202e49;
  color: var(--lightColor);
}

.errorMsg {
  color: var(--redColor);
  text-align: center;
  padding-top: 8px;
}

.button {
  color: #00C1FE;
  width: 100%;
  padding: 16px;
  font-size: 16px;
  font-family: "Be Vietnam Pro", sans-serif;
  font-weight: 600;
  margin-top: 12px;
  cursor: pointer;
  background: #303351 0% 0% no-repeat padding-box;
  border: 1px solid #00C1FE;
  border-radius: 8px;
}

.disabled {
  box-shadow: none;
  color: #2C386E;
  cursor: not-allowed;
  background: #737797;
  border: none;
}

.disabled:hover {
  background: var(--disableBkg);
  color: var(--disableColor);
}

.formButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}