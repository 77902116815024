.modalOverlay {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--modalOverlayBkg);
}

.logoutOverlay {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  border: 2px solid #3f519f;
  border-radius: 16px;
  /* background: var(--modalBkg); */
}
