.backgroundSetter {
  background-image: url("../../assets/HomeImages/homepage-bg-img.webp");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
}

.gameCategorySectionWrapper {
  display: flex;
  height: auto;

  /* background-image: url("../../assets/HomeImages/BG.webp"); */
  /* align-items: center; */
  flex-direction: column;
  gap: 60px;
  padding: 3% 7%;
}

/* .categorySlider {
 width: 100%;
  padding: 0% 0 0% 0; 
} */

.marqueeWrapper {
  display: flex;
  align-items: center;
  background: linear-gradient(90deg, #040415 0%, #1b2647 100%);
  height: 35px;
  color: #fff;
  padding: 0 15%;
}

.marqueeWrapper img {
  height: 25px;
  padding-right: 10px;
}

.movingBanner {
  width: 100%;
  padding: 2% 0;
}

.movingBanner img {
  width: 100%;
}

@media only screen and (max-width: 1600px) {
  .gameCategorySectionWrapper {
    padding: 2% 4%;
  }
}

@media only screen and (max-width: 1440px) {
  .gameCategorySectionWrapper {
    padding: 2% 4%;
  }
}