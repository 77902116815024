.sectionContent {
  display: flex;
  justify-content: center;
  gap: 25px;
  width: 100%;
  box-sizing: border-box;
  border: 3px solid #FFFF6D;
  border-radius: 16px;
}

.gamesListWrapper {
  display: inline-block;
  justify-content: center;
  overflow: auto;
  width: 25%;
  justify-content: center;
}

.loginForm {
  position: absolute;
  top: 0;
  right: 0;
  visibility: hidden;
}

.transactionButtons {
  position: absolute;
  top: 0;
  right: 0;
  visibility: hidden;
}

.gamesBodyList {
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.gameImageWrapper {
  cursor: pointer;
}

.gameImageWrapper img {
  width: 100%;
  /* height: 100% */
}

.overlayButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #06B9F3;
  background: linear-gradient(180deg, #00C1FE 0%, #163D75 100%);
}

.noImage {
  display: flex;
  justify-content: center;
  align-items: center;
}

.noImage>h4 {
  color: var(--lightColor);
  margin: 5px 0;
}

/* Hover effect */
.gamesBodyList:hover .dagaTitleImage {
  opacity: 0.6;
}